import { useState, useEffect } from '@wordpress/element';

export const useInitialURLParams = () => {
    const [params, setParams] = useState({
        orderType: null,
        thc_min: null,
        thc_max: null
    });

    useEffect(() => {
        const updateParams = () => {
            const searchParams = new URLSearchParams(window.location.search);
            setParams({
                orderType: searchParams.get('orderType') || null,
                thc_min: searchParams.get('thc_min') || null,
                thc_max: searchParams.get('thc_max') || null
            });
        };

        updateParams();

        window.addEventListener('popstate', updateParams);

        return () => {
            window.removeEventListener('popstate', updateParams);
        };
    }, []);

    return params;
};

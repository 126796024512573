const ErrorComponent = ({ error, componentStack, resetError }) => {
    return (
        <div className='headless-error-component'>
            <p style={{ color: '#ff0000', fontWeight: 'bold' }}>
                We apologize, but this area has encountered an error.
            </p>
            <button style={{ display: 'block' }} onClick={resetError}>
                Reset
            </button>
            <small style={{ display: 'block' }}>
                You may try resetting the area with the above button.
            </small>
            <small>
                If the error persists, please refresh the page from your
                browser.
            </small>
            <small style={{ display: 'block', marginTop: '2rem' }}>
                <code>{error?.toString()}</code>
            </small>
        </div>
    );
};

export default ErrorComponent;

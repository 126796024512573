import { useMutation, useQueryClient } from '@tanstack/react-query';
import { dutchieMutation } from '../api/api';
import {
    sentryCapture,
    hasCommonErrCode,
    getErrorMsgsToSet,
} from '../errorHandlers';
import * as storage from '../browserStorage';
import { storageItemName } from '../config';

export const useAddItem = (
    retailerId,
    checkoutId,
    setSidebarToggle,
    setErrors = null,
    handleTracking = null
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables) =>
            dutchieMutation('AddItemToCheckout', variables).then(
                (res) => res.addItem
            ),
        onSuccess: (data) => {
            queryClient.setQueryData(
                [
                    'FetchCartDetails',
                    {
                        checkoutId,
                        retailerId,
                    },
                ],
                data
            );

            setSidebarToggle(true);

            if (setErrors) {
                setErrors([]);
            }

            if (handleTracking) {
                handleTracking();
            }
        },
        onError: (err) => {
            if (err?.response?.errors) {
                if (setErrors) {
                    const errMsgs = getErrorMsgsToSet(err.response.errors);
                    setErrors(errMsgs);
                }
                if (hasCommonErrCode(err.response.errors)) {
                    console.warn(err.response.errors);
                } else {
                    sentryCapture(err);
                }
            } else {
                sentryCapture(err);
            }
        },
    });
};

export const useCreateCart = (retailerId) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['CreateCheckout', retailerId],
        mutationFn: (variables) =>
            dutchieMutation('CreateCheckout', variables).then(
                (res) => res.createCheckout
            ),
        onSuccess: (data) => {
            queryClient.setQueryData(
                [
                    'FetchCartDetails',
                    {
                        checkoutId: data.id,
                        retailerId,
                    },
                ],
                data
            );

            storage.setItem(
                storageItemName,
                JSON.stringify({
                    checkoutId: data.id,
                    retailerId: retailerId,
                    createdAt: Date.now(),
                })
            );
        },
        onError: (err) => {
            if (err?.response?.errors) {
                if (hasCommonErrCode(err.response.errors)) {
                    console.warn(err.response.errors);
                } else {
                    sentryCapture(err);
                }
            } else {
                sentryCapture(err);
            }
        },
    });
};

export const useRemoveItem = (
    retailerId,
    setErrors,
    handleTracking,
    surfside,
    analytics
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables) => {
            const { item, currentVariant } = variables;
            delete variables.item;
            delete variables.currentVariant;

            return await dutchieMutation('RemoveItemFromCheckout', {
                ...variables,
                itemId: item.id,
            }).then((res) => ({ data: res.removeItem, item, currentVariant }));
        },
        onSuccess: ({ data, item, currentVariant }) => {
            queryClient.setQueryData(
                [
                    'FetchCartDetails',
                    {
                        checkoutId: data.id,
                        retailerId,
                    },
                ],
                data
            );

            handleTracking(surfside, analytics, item, currentVariant);
        },
        onError: (err) => {
            if (err?.response?.errors) {
                const errMsgs = getErrorMsgsToSet(err.response.errors);
                setErrors(errMsgs);

                if (hasCommonErrCode(err.response.errors)) {
                    console.warn(err.response.errors);
                } else {
                    sentryCapture(err);
                }
            } else {
                sentryCapture(err);
            }
        },
    });
};

export const useUpdateCheckout = (retailerId) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables) =>
            dutchieMutation('UpdateCheckout', variables).then(
                (res) => res.updateCheckout
            ),
        onSuccess: (data) => {
            queryClient.setQueryData(
                [
                    'FetchCartDetails',
                    {
                        checkoutId: data.id,
                        retailerId,
                    },
                ],
                data
            );
        },
        onError: (err) => {
            if (err?.response?.errors) {
                if (hasCommonErrCode(err.response.errors)) {
                    console.warn(err.response.errors);
                } else {
                    sentryCapture(err);
                }
            } else {
                sentryCapture(err);
            }
        },
    });
};

export const useUpdateQuantity = (
    retailerId,
    checkoutId,
    setSidebarToggle = null,
    setErrors = null,
    handleTracking = null
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables) =>
            dutchieMutation('UpdateItemQuantity', variables).then(
                (res) => res.updateQuantity
            ),
        onSuccess: (data) => {
            queryClient.setQueryData(
                [
                    'FetchCartDetails',
                    {
                        checkoutId,
                        retailerId,
                    },
                ],
                data
            );

            if (setSidebarToggle) {
                setSidebarToggle(true);
            }

            if (setErrors) {
                setErrors([]);
            }

            if (handleTracking) {
                handleTracking();
            }
        },
        onError: (err) => {
            if (err?.response?.errors) {
                if (setErrors) {
                    const errMsgs = getErrorMsgsToSet(err.response.errors);
                    setErrors(errMsgs);
                }

                if (hasCommonErrCode(err.response.errors)) {
                    console.warn(err.response.errors);
                } else {
                    sentryCapture(err);
                }
            } else {
                sentryCapture(err);
            }
        },
    });
};

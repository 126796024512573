import * as Sentry from '@sentry/react';

/**
 * Pass exception directly to Sentry with no additional tags or info.
 * @param {Error} err Error object.
 * @param {object|null}
 */
export function sentryCapture(err, extra = null) {
    Sentry.captureException(err, (scope) => {
        switch (err?.level) {
            case 'debug':
                console.debug(err);
                scope.setLevel('debug');
            case 'info':
                console.info(err);
                scope.setLevel('info');
            case 'log':
                console.log(err);
                scope.setLevel('log');
            case 'warning':
                console.warn(err);
                scope.setLevel('warning');
                break;
            case 'error':
                console.error(err);
                scope.setLevel('error');
            case 'fatal':
                console.error(err);
                scope.setLevel('fatal');
            default:
                break;
        }

        if (extra) {
            scope.setExtras(extra);
        }

        return scope;
    });
}

/**
 * Checks GQL response for common errors that are expected and shouldn't be logged to Sentry.
 * @param {array} errors
 * @returns {bool}
 */
export const hasCommonErrCode = (errors) => {
    const codes = [
        6, // "Sorry! You've reached the 2.5oz purchase limit for flower due to state regulations."
    ];

    return errors.some((err) => {
        if (err?.extensions && err.extensions.hasOwnProperty('code')) {
            return codes.includes(err.extensions.code);
        }
    });
};

/**
 * Get array of error messages from Dutchie error response.
 * @param {array} errors Dutchie error response.
 * @returns {array} Array of message strings.
 */
export const getErrorMsgsToSet = (errors) => {
    return errors.map((error) => {
        if (error?.extensions?.errors) {
            return error.extensions.errors.map((extError) => extError.detail);
        } else {
            return error.message;
        }
    });
};

import { useEffect, useRef } from '@wordpress/element';
import unescape from 'lodash.unescape';
import { parseScriptTag } from './util';

/**
 * If `fallback.enable === true` no queries will run and this component will load.
 * Similar in function to `Shortcode.jsx`.
 */
const Fallback = ({ script, shortcodeData, rootId }) => {
  const { shortcode } = shortcodeData;
  const embedRef = useRef(null);

  useEffect(() => {
    let key = rootId.split('-').pop();
    const backendLoadingSpinner = document.getElementById(`loading-${key}`);
    if (backendLoadingSpinner) {
      backendLoadingSpinner.remove();
    }
  }, [rootId]);

  useEffect(() => {
    if (shortcode === 'headlessmenu' && embedRef.current) {
      const scriptAttributes = parseScriptTag(unescape(script));
      
      if (scriptAttributes) {
        const existingScript = embedRef.current.querySelector(`#${scriptAttributes.id}`);

        if (!existingScript) {
          const newScript = document.createElement('script');
          for (const [attr, value] of Object.entries(scriptAttributes)) {
            newScript.setAttribute(attr, value);
          }
          embedRef.current.appendChild(newScript);
        }
      }
    }
  }, [shortcode, script]);

  switch (shortcode) {
    case 'headlessmenu':
      return <div id="dutchie-embed" ref={embedRef}></div>;
    case 'headlessproducts':
      return (
        <a href="/shop" className="headless-button">
          Click here to shop!
        </a>
      );
    default:
      return null;
  }
};

export default Fallback;
